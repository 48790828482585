import { useSystemContext } from '../Context/SystemContext';
import Login from '../pages/Login';
import Header from './Header';
// {process.env.REACT_APP_VERSION}

const Layout = ({ children, ...rest }) => {
    let System = useSystemContext();

    if (System.property.user?.mobile === true) {
        return <Login />;
    }

    return (
        <div className="-mb-12 min-w-screen bg-yellow-50">
            <Header />
            <div className="block">
                <div className="">
                    <div className="flex">{children}</div>
                </div>
                <div className="inset-x-0 bottom-0 flex items-center justify-center ext-xs">
                    <div className="h-4"></div>
                </div>
            </div>
            <div className="fixed inset-x-0 bottom-0 flex items-center justify-center text-xs">
                <div className="inline-flex px-2 border-t border-l border-r border-gray-300 rounded-t bg-yellow-50">
                    Direitos reservados à Kom Tecnologia - CNPJ: 29.508.924/0001-85 - Versão: {process.env.REACT_APP_VERSION}{' '}
                </div>
            </div>
        </div>
    );
};

export default Layout;
