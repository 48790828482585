import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormInput, Input } from '../components/Input';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';

const yup = require('../interface/yup-br');

const Profile = () => {
    const System = useSystemContext();
    const navigate = useNavigate();
    if (System.property.user?.mobile === true) {
        navigate('/login');
    }
    const UserDocument = '/crew/' + System.property.user.crew_id;
    return (
        <Layout>
            <div className="grow"></div>
            <div className="w-1/5 mt-4 bg-white shadow grow-0">
                <FormInput
                    document={UserDocument}
                    title="Perfil do Usuário"
                    validationSchema={yup.object().shape({
                        nome: yup.string().required(),
                        email: yup.string().email().required(),
                        senha: yup.string().required(),
                    })}
                    onAfterSave={() => {
                        navigate('/');
                    }}
                    onRenderFields={({ setFieldValue }) => {
                        return (
                            <div>
                                <Input name="nome" label="Nome" className="block w-full" />
                                <Input name="email" label="E-mail" className="w-full" />
                                <Input name="senha" label="Senha" type="password" />
                            </div>
                        );
                    }}
                />
            </div>
            <div className="grow"></div>
        </Layout>
    );
};

export default Profile;
