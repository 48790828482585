import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useSystemContext } from '../Context/SystemContext';

import { SystemContextProvider } from '../Context/SystemContextProvider';

import { BrowserView, MobileView } from 'react-device-detect';
import SystemAdminPage from './Admin';
import Ajuda from './Ajuda';
import CadastroEmpresa from './CadastroEmpresa';
import DatabasePage from './Database';
import Login from './Login';
import Mobile from './Mobile';
import Profile from './Profile';
import ProfileConsultor from './ProfileConsultor';

export default function Router() {
    const System = useSystemContext();

    const PrivateRoute = ({ children }) => {
        return System.property.logged ? children : <Navigate to="/login" />;
    };

    function DirectDatabase() {
        let param = useParams();
        return <DatabasePage defaultMenu={param.requisito} />;
    }
    function DirectDatabaseAutoEdit() {
        const { setAutoEdit } = useSystemContext();
        let param = useParams();
        setAutoEdit(param.id);
        return <DatabasePage defaultMenu={param.requisito} />;
    }

    //console.log('===>', System.property.user);
    if (!('mobile' in System.property.user)) {
        return (<Login />);
    }

    return (
        <BrowserRouter>
            <SystemContextProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <BrowserView>
                                    {System.property.user.mobile
                                        ? <Login />
                                        : <DatabasePage defaultMenu={10} />
                                    }
                                </BrowserView>
                                <MobileView>
                                    {System.property.user.mobile === false
                                        ? <Login />
                                        : <Mobile />
                                    }
                                </MobileView>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/redir"
                        element={
                            <PrivateRoute>
                                <Navigate to="/" replace={true} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <DatabasePage defaultMenu={10} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/database"
                        element={
                            <PrivateRoute>
                                <DatabasePage defaultMenu={10} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/database/:requisito"
                        element={
                            <PrivateRoute>
                                <DirectDatabase />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/database/:requisito/:id"
                        element={
                            <PrivateRoute>
                                <DirectDatabaseAutoEdit />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profileconsultor"
                        element={
                            <PrivateRoute>
                                <ProfileConsultor />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/empresa"
                        element={
                            <PrivateRoute>
                                <CadastroEmpresa />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="password"
                        element={
                            <PrivateRoute>
                                <h1> Alterar Senha</h1>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/admin"
                        element={
                            <PrivateRoute>
                                <SystemAdminPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/ajuda"
                        element={
                            <PrivateRoute>
                                <Ajuda />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <main style={{ padding: '1rem' }}>
                                <p>There's nothing here! :(</p>
                            </main>
                        }
                    />
                </Routes>
            </SystemContextProvider>
        </BrowserRouter>
    );
}
